import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby-plugin-spinner"

import AjouterPanier from "../components/ajouterPanier"

import SEO from "./../components/seo"
import Layout from "../components/layout"
import ImgPa from "../components/img/imgPa"

class AccessoiresProductPage extends React.Component {
  render() {
    const styleImageDetail = {
      width: 300,
      height: 300,
      position: "relative",
    }

    let d = new Date()
    d.setDate(d.getDate() + 7)
    const { product } = this.props.pageContext
    return (
      <>
        <Layout banner={false} location={this.props.location}>
          <SEO
            title={product.pa_libelle}
            description={product.pa_description}
            canonical={this.props.location.pathname.indexOf('pochette') > -1 ? 
              (process.env.GATSBY_URL_ACTUEL + "/accessoires/") : (process.env.GATSBY_URL_ACTUEL + this.props.location.pathname)}
            jsonLd={[
              {
                "@context": "http://schema.org",
                "@type": "Product",
                sku: product.pa_reference,
                mpn: "A-" + product.pa_reference,
                identifier: product.pa_reference,
                description: product.pa_description,
                name: product.pa_libelle,
                image: process.env.GATSBY_URL_ACTUEL + "/" + product.pa_image_detail,
                url: process.env.GATSBY_URL_ACTUEL + this.props.location.pathname,
                brand: {
                  "@type": "Brand",
                  name: "Encadrement sur mesure",
                },
                offers: {
                  "@type": "Offer",
                  availability: "http://schema.org/InStock",
                  price: (product.pa_prix_vente_ht * 1.2 * 1.43).toFixed(2),
                  url: process.env.GATSBY_URL_ACTUEL + this.props.location.pathname,
                  priceCurrency: "EUR",
                  priceValidUntil: d.toISOString().substr(0, 10),
                },
                itemCondition: "https://schema.org/NewCondition",
              },
              {
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 1,
                    name: "Accueil",
                    item: process.env.GATSBY_URL_ACTUEL,
                  },
                  {
                    "@type": "ListItem",
                    position: 2,
                    name: "Accessoires",
                    item: `${process.env.GATSBY_URL_ACTUEL}/accessoires/`,
                  },
                  {
                    "@type": "ListItem",
                    position: 3,
                    name: product.pa_libelle,
                    item: `${process.env.GATSBY_URL_ACTUEL}${this.props.location.pathname}`,
                  },
                ],
              },
            ]}
          />
          <nav className="breadcrumb mb-0 mt-4">
            <ul>
              <li>
                <Link to="/">Accueil</Link>
              </li>
              <li>
                <Link to={`${process.env.GATSBY_URL_ACTUEL}/accessoires/`}>Accessoires</Link>
              </li>
              <li>
                <span className="pl-3">{product.pa_libelle}</span>
              </li>
            </ul>
          </nav>

          <h1>{product.pa_libelle}</h1>
          <div className="columns is-multiline">
            <div className="column is-4 ">
              <div style={{ width: "75%", margin: "0 auto" }}>
                <ImgPa id={product.pa_id} />
              </div>
            </div>

            <div className="columns is-multiline column is-8 ">
              <div className="column is-full">
                <p>
                  Réf. <strong>{product.pa_reference}</strong>
                </p>
                <p style={{ marginTop: "1.5rem", textAlign: "justify" }}>{product.pa_description}</p>
              </div>
              <div className="column is-half">
                {product.pa_image_detail === "" ? "" : <img style={styleImageDetail} src={product.pa_image_accessoires_detail} alt="details" />}
              </div>

              <div style={{ fontSize: "1.1rem", textAlign: "right" }} className="column is-half">
                <AjouterPanier product={product} pa={this.props.pageContext.pa} />
              </div>
            </div>
          </div>
        </Layout>
      </>
    )
  }
}

AccessoiresProductPage.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
}

export default AccessoiresProductPage
